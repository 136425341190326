.right{
        width: 40rem;
        height: 50rem;
        background-color: black;
        position: relative;
        left: 50rem;
        top: -50rem;
        right: 2rem;
}
.title{
    color: green;
    z-index: 1035;
    position: relative;
    left: 15rem;
    top: 5.3rem;
    font-size: 2.7rem;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}
.newAcc{
    color: white;
    position: relative;
    top: 6rem;
    left: 14rem;
    font-size: 1.3rem;
    cursor: pointer;

}
.email{
    color: white;
    z-index: 1040;
    justify-content: space-between;
    position: relative;
    left: 18rem;
    top: 5.5rem;
    cursor: pointer;

}
.google{
    z-index: 1045;
    color: white;
    position: relative;
    left: 21.3rem;
    top: 3rem;
    cursor: pointer;
}
    
.input{
    position: relative;
    border: none;
    top: 5rem;
    left: 6rem;
    font-size: 1.7rem;
    border-radius: 10rem;
}
.checkbox{
    width: 2rem;
    font-size: 10rem;
    position: relative;
    bottom: .2rem;
}
.checkboxText{
    color: white;
    font-size: .8rem;
    position: relative;
    left: 1.8rem;
    bottom: 1.9rem;
}
.btn2{
    width: 30rem;
    border-radius: 10rem;
    background-color: rgb(126, 126, 126);
    color: white;
    position: relative;
    right: 1rem;
    bottom: 4.3rem;
}
.btn2-active{
    width: 30rem;
    border-radius: 10rem;
    background-color: green;
    color: white;
    position: relative;
    right: 1rem;
    bottom: 4.3rem;
    /* transform: scale(45deg); */
}
.text1{
    color: white;
    font-size: .8rem;
    position: relative;
    bottom: 3.5rem;
}
.text2{
    color: white;
    font-size: .8rem;
    position: relative;
    bottom: 3.5rem; 
    right: 5.7rem; 
}