.left{
    width: 40rem;
    height: 47.3rem;
    background-color: black;
    position: relative;
    top: 0rem;
    left: 2rem;
}
body{
    background-color: rgb(5, 5, 5);
}
.alreadyAcc{
    color: white;
    z-index: 1010;
    position: relative;
    left: 8rem;
    top: 2.3rem;
}
.btn1{
    width: 6rem;
    height: 2.3rem;
    position: relative;
    left: 27rem;
    top: 0rem;
    z-index: 1005;
    border: none;
    background-color: green;
    color: white;
    border-radius: 10rem;
    cursor: pointer;
}
.conclution{
    color: white;
    z-index: 1050;
    position: relative;
    left: 6rem;
    top: 35rem;
}
img{
    position: relative;
    top: -10rem;
    z-index: 1000;
}